import * as React from "react"
import DiscountServices from "../components/DiscountServices"
import ExtendedComunityScholarship from "../components/ExtendedComunityScholarship"

import Seo from "../components/Seo"

const ExtendedScholarshipPage = () => (
  <>
    <Seo title="Becas Comunidad Extendida" />
    <ExtendedComunityScholarship />
    <DiscountServices />
  </>
)

export default ExtendedScholarshipPage
