import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

const ExtendedComunityScholarship = () => (
  <section className="comunityscholarship-section">
    <div className="comunityscholarship-content">
      <div className="comunityscholarship-box-title-grid">
        <div />
        <div className="comunityscholarship-cube" />
        <p className="comunityscholarship-title">Becas Comunidad</p>
        <div className="comunityscholarship-cube" />
        <div />
      </div>
      <p className="comunityscholarship-title-extended">Extendida</p>
      <div className="comunityscholarship-grid">
        <div className="comunityscholarship-icon-div">
          <p className="comunityscholarship-icon-text">Beca por Convenio</p>
          <div className="comunityscholarship-icon-box">
            <div className="comunityscholarship-icon-before" />
            <StaticImage
              placeholder="blurred"
              className="comunityscholarship-icon"
              alt=""
              src="../images/Convenio.png"
            />
          </div>
        </div>
        <div className="comunityscholarship-description-grid">
          <p className="comunityscholarship-text">
            20% de descuento para hijos, cónyuges o trabajadores que laboren en
            alguna dependencia de Gobierno del Estado de Hidalgo. <br />
            <br />
            Se han signado convenios con distintos Ayuntamientos del Estado,
            para otorgar descuentos a sus colaboradores, esposas (os), hijos
            (as) y su población en general.
            <br /> <br />
            Consulta con el área de becas cuáles son las Presidencias
            Municipales con la que se tiene convenio, así como los
          </p>
          <p className="comunityscholarship-text">
            términos y condiciones.
            <br /> <br />
            <b>No acumulable con otra promoción</b>
          </p>
        </div>
      </div>
      <div className="comunityscholarship-grid">
        <div className="comunityscholarship-icon-div">
          <p className="comunityscholarship-icon-text">
            Convenio Instituto Hidalguense De Financiamiento A La Educación
            Superior
          </p>
          <div className="comunityscholarship-icon-box">
            <div className="comunityscholarship-icon-before" />
            <StaticImage
              placeholder="blurred"
              className="comunityscholarship-icon"
              alt=""
              src="../images/IHFES.png"
            />
          </div>
        </div>
        <div className="comunityscholarship-description-grid">
          <p className="comunityscholarship-text">
            Se brindará financiamiento educativo a la comunidad estudiantil,
            personal docente y administrativo que cumplan con los requisitos
            correspondientes a los lineamientos establecidos por el IHFES.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default ExtendedComunityScholarship
